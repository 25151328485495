import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { IconContext } from "react-icons"
import { FaLine, FaFacebookSquare } from "react-icons/fa"
import Img from "gatsby-image"

class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const images = data.images.edges

    return (
      <Layout>
        <SEO title="Home" />
        <Header siteTitle="ติดต่อเรา" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="entry-content">
                <p>
                  เราเป็นฟรีแลนซ์ (Freelance) เว็บโปรแกรมเมอร์และใช้ชื่อ
                  toSKYsoft (ทูสกายซอฟต์) ในการรับงาน โดยในนาม toSKYsoft
                  มีฟรีแลนซ์ 2 คนคือ ย้งยี้ (ติดต่อลูกค้า & ออกแบบ) และ เคน
                  (พัฒนาเว็บไซต์)
                </p>
                <p>
                  เรารับพัฒนาเว็บไซต์/ระบบเว็บไซต์ตามลูกค้าต้องการด้วยภาษา PHP
                  และการใช้ CMS WordPress
                  ในการพัฒนาเว็บไซต์แบบครบลูปตั้งแต่จดโดเมน ดูแลโฮสต์
                  พัฒนาเว็บไซต์ และบริการหลังการขาย
                </p>
                <p>
                  นอกจากพัฒนาเว็บไซต์เรายังรับสอนการใช้งาน Wordpress เบื้องต้น
                  รวมถึงมีบริการเกี่ยวกับโซเชี่ยลมีเดีย เช่น รับปลดล็อกเฟซบุ๊ค
                  รับลงโฆษณาเฟซบุ๊ค/Instagram และบริการอื่น ๆ
                  ที่จะเพิ่มเติมอีกในอนาคต
                </p>
                <p>
                  พวกเราทีมงาน toSKYsoft (ทูสกายซอฟต์)
                  ใส่ใจในบริการลูกค้าอย่างจริงใจ
                  เต็มที่กับการให้บริการในทุกเรื่อง
                  ดังนั้นหากคุณมีข้อสงสัยหรือปัญหาใดๆ อย่าลังเลที่จะติดต่อเรา
                  โดยคุณสามารถที่จะติดต่อเราผ่านช่องทางการติดต่อต่างๆ
                  ตามด้นล่างได้ทันที
                </p>
                <div className="pt-3">
                  <p>
                    <IconContext.Provider
                      value={{
                        color: "#00b900",
                        size: "2em",
                        className: "mr-3",
                      }}
                    >
                      <FaLine />
                    </IconContext.Provider>
                    Line ID:
                    <a
                      href="https://line.me/R/ti/p/%40ykz5170o"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @toskysoft
                    </a>
                  </p>
                  <p>
                    <IconContext.Provider
                      value={{
                        color: "#4468b2",
                        size: "2em",
                        className: "mr-3",
                      }}
                    >
                      <FaFacebookSquare />
                    </IconContext.Provider>
                    Facebook:
                    <a
                      href="https://www.facebook.com/toskysoft"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      toSKYsoft
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section>
            <h2>Certificates</h2>

            <div className="row">
              {images.map(({ node }) => {
                const { fluid } = node.childImageSharp
                return (
                  <div
                    key={node.id}
                    className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5"
                  >
                    <Img fluid={fluid} />
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    images: allFile(filter: { relativeDirectory: { eq: "certificates" } }) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ContactPage
